import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario'
import { iBodyReqReporteBaremosPeriodoActual, RepositoryMain } from '../Domain'

export class UseCaseReporteBaremosPeriodoActual {

    private repository: RepositoryMain

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
    }

    async exec(user: EntityDataUsuario) {
        return this._exec(user)
    }
    private async _exec(user: EntityDataUsuario) {
        const params: iBodyReqReporteBaremosPeriodoActual = {
            Delegacion: { Codigo: user.Delegacion.Codigo, Nombre: user.Delegacion.Nombre },
            Identificacion: user.Identificacion,
            Pais: { Codigo: user.Pais.Codigo, Nombre: user.Pais.Nombre }
        }
        return await this.repository.reporteBaremosPeriodoActual(params)
    }

}