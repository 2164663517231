import { createSlice } from '@reduxjs/toolkit'
import { IReporteBaremosPeriodoActual } from '../Domain'

interface IinitialState {
    reporteBaremosPeriodoActual: IReporteBaremosPeriodoActual
}

const initialState: IinitialState = {
    reporteBaremosPeriodoActual: new IReporteBaremosPeriodoActual()
}

const slice = createSlice({
    name: 'homeSlice',
    initialState,
    reducers: {
        setReporteBaremosPeriodoActual: (state, { payload }) => {
            state.reporteBaremosPeriodoActual = payload
        }
    }
})

export const {
    setReporteBaremosPeriodoActual
} = slice.actions
export const homeReducer = slice.reducer