import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 1,
      //barThickness: 30,
      barPercentage: 0.5,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        boxWidth: 10,
        //maxWidth: 50,
      },
    },
  },
};
type ElementHorizontalBarChartProps = {
  labels: string[];
  dataGroups: number[];
  task: string;
};
export const ElementHorizontalBarChart: React.FC<
  ElementHorizontalBarChartProps
> = ({ labels, dataGroups, task }) => {
  const data: ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        // STOCK PERSONAL
        label: task,
        data: dataGroups,
        backgroundColor: ["#ff0000", "#00a44b", "#00b0f0", "#ffc000"],
      },
    ],
  };


  return <Bar data={data} options={options} />;
};

// const labels = [''];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'T.Stock',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ff0000',
//     },
//     {
//       label: 'T. Liqui',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00a44b',
//     },
//     {
//       label: 'T.Devo',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00b0f0',
//     },
//     {
//       label: 'T. Despa',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ffc000',
//     },
//   ],
// };

// export function ElementHorizontalBarChart() {
//   return <Bar options={options} data={data} />;
// }

// import React from 'react';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   indexAxis: 'y' as const,
//   elements: {
//     bar: {
//       borderWidth: 1,
//       //barThickness: 30,
//       barPercentage: 0.5,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'bottom' as const,
//       labels: {
//         boxWidth: 10,
//         //maxWidth: 50,
//       },
//     },
//   },
// };

// const labels = [''];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'T.Stock',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ff0000',
//     },
//     {
//       label: 'T. Liqui',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00a44b',
//     },
//     {
//       label: 'T.Devo',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#00b0f0',
//     },
//     {
//       label: 'T. Despa',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: '#ffc000',
//     },
//   ],
// };

// export function ElementHorizontalBarChart() {
//   return <Bar options={options} data={data} />;
// }
