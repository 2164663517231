import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import Stepper from "../../../../shared/Components/ElementStepper";
import ElementStepperContent from "../../../../shared/Components/ElementStepperContent";
import './Style.scss';
import { InputSelect, InputText } from '../../../../shared/Components/ElementInputsCostume';
import { DtoFormAddValorizacion, DtoSelectAsignacionValorizacion } from '../Domain/DtoAsignacionValorizacion';
import CardDetallePreLiquidacion from '../../../../shared/Components/Programados/CardDetallePreLiquidacion';
// import { EntityModal } from '../../../../shared/Domain/EntityModal';
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos';
import { AgregarMaterial } from './AgregarMaterial';
import { DtoItems } from '../../../../../app/Domain/DtoItems';
import { DtoTipoMaterial } from '../Domain/DtoTipoMaterial';
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal';
import { CargarAdjuntos } from './CargarAdjuntos';
import { FormikErrors, FormikTouched } from 'formik';

interface PropsViewMain {
    onChangeContentForTab: (newKey: number) => void;
    onSave: () => void;
    onAddValorizacion: () => void;
    onRemoveValorizacion: (value: string) => void;
    keyTab: number;
    onChange: (name: string, value: any) => void;
    formAddValorizacion: {
        values: DtoFormAddValorizacion
        touched: FormikTouched<DtoFormAddValorizacion>
        errors: FormikErrors<DtoFormAddValorizacion>
        handleBlur: (e: React.FocusEvent<any>) => void
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    }
    ID_Trabajo: string;
    asignacionesTrabajo: DtoSelectAsignacionValorizacion[];
    Asignaciones: DtoFormAddValorizacion[];
    // modalData: EntityModal;
    // setModalData: React.Dispatch<React.SetStateAction<EntityModal>>;
    trabajo: DtoTrabajos;
    Items: DtoItems[];
    dataTipoMaterial: DtoTipoMaterial[];
    dataStockPersonal: DtoResponseSelectStockPersonal[];
    setShowImageViewer: React.Dispatch<React.SetStateAction<boolean>>
    setFileImageViewer: React.Dispatch<React.SetStateAction<File | null>>
};

export const ViewMain = (props: PropsViewMain) => {
    const {
        onChangeContentForTab,
        onSave,
        onAddValorizacion,
        onRemoveValorizacion,
        keyTab,
        onChange,
        formAddValorizacion,
        asignacionesTrabajo,
        Asignaciones,
        ID_Trabajo,
        // setModalData,
        trabajo,
        Items,
        dataTipoMaterial,
        dataStockPersonal,
        setFileImageViewer,
        setShowImageViewer
    } = props;

    const languageTranslate = LanguageTranslate();
    // const openModal = () => setModalData((prev) => ({ ...prev, show: true }));

    return (
        <div className='ViewTrabajo'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[
                    { text: languageTranslate.moduloMain.textoProgramados, navigate: true, path: '/main/programados' },
                    { text: languageTranslate.menu['002'].titulo, navigate: true, path: '/main/programados/trabajos' },
                    { text: ID_Trabajo, navigate: false, path: '' }
                ]} />
                <div className='d-flex justify-content-center'>
                    <h6>{Object.keys(trabajo).length > 0 && trabajo.ColeccionObras[0].OrdenTrabajo}</h6>
                </div>
                <Stepper
                    data={[
                        languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.text,
                        languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper2.text,
                    ]}
                    onChange={onChangeContentForTab}
                    stepPosition={props.keyTab}
                />

                {/** STEP 1 */}
                <ElementStepperContent
                    step={1}
                    currentStep={keyTab}
                    title={languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.text}
                    onSubmit={onChangeContentForTab}
                >
                    <InputSelect
                        label={languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.content.valorizacion}
                        name='Valorizacion'
                        onChange={onChange}
                        values={formAddValorizacion.values}
                        isRequired
                        options={asignacionesTrabajo}
                        loading={false}
                        disabled={false}
                        disabledVirtualized
                    />
                    {
                        (formAddValorizacion.values.Valorizacion.dataComplete.UnidadObra.Codigo !== '0' &&
                        formAddValorizacion.values.Valorizacion.value)
                            ? <p
                                style={{ fontSize: 14 }}
                            >UO: <span style={{ fontStyle: 'italic', color: 'gray' }}>
                                    {formAddValorizacion.values.Valorizacion.dataComplete.UnidadObra.Codigo}{' - '}
                                    {formAddValorizacion.values.Valorizacion.dataComplete.UnidadObra.Nombre}
                                </span>
                            </p>
                            : null
                    }
                    <table className="table table-striped table-bordered mb-3">
                        <thead className="table-bordered">
                            <tr className=''>
                                <th scope="col"></th>
                                <th style={{ fontSize: '1rem' }} className='align-middle text-center' scope="col">{languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.content.CantidadAsignada}</th>
                                <th style={{ fontSize: '1rem' }} className='align-middle text-center' scope="col">{languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.content.CantidadDisponible}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: '1rem' }}>{languageTranslate.cardDetallePreLiquidacion.ManoObra}</td>
                                <td style={{ fontSize: '1rem' }} className='text-center'>{formAddValorizacion.values.CantidadMOAsignada}</td>
                                <td style={{ fontSize: '1rem' }} className='text-center'>{formAddValorizacion.values.CantidadMODisponible ? Number(formAddValorizacion.values.CantidadMODisponible).toFixed(2) : formAddValorizacion.values.CantidadMODisponible}</td>
                            </tr>
                            {
                                trabajo.Pais?.Codigo === '512' &&
                                <tr>
                                    <td style={{ fontSize: '1rem' }}>{languageTranslate.cardDetallePreLiquidacion.UnidadObra}</td>
                                    <td style={{ fontSize: '1rem' }} className='text-center'>{formAddValorizacion.values.CantidadUOAsignada}</td>
                                    <td style={{ fontSize: '1rem' }} className='text-center'>{formAddValorizacion.values.CantidadUODisponible}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <InputText
                        type='number'
                        label={languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.content.CantidadMOPreliquidada}
                        name='CantidadMOPreliquidada'
                        onChange={onChange}
                        values={props.formAddValorizacion.values}
                        isRequired
                        disabled={false}
                        isHorizontal={true}
                    />
                    {
                        trabajo.Pais?.Codigo === '512' &&
                        <InputText
                            type='number'
                            label={languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper1.content.CantidadUOPreliquidada}
                            name='CantidadUOPreliquidada'
                            onChange={onChange}
                            values={props.formAddValorizacion.values}
                            disabled={false}
                            isHorizontal={true}
                        />
                    }
                    {
                        trabajo?.Pais?.Codigo === '480' &&
                        <AgregarMaterial
                            Items={Items}
                            formAddValorizacion={formAddValorizacion}
                            onChange={onChange}
                            dataTipoMaterial={dataTipoMaterial}
                            dataStockPersonal={dataStockPersonal}
                        />
                    }
                    <CargarAdjuntos
                        formAddValorizacion={formAddValorizacion}
                        setFileImageViewer={setFileImageViewer}
                        setShowImageViewer={setShowImageViewer}
                    />
                    {/* <div className="form-row d-flex justify-content-between mb-3">
                        <div className='d-flex align-self-center'>
                            <span className="camera">
                                <ElementInputFileImage
                                    icon={'fa-solid fa-camera'}
                                    // capture
                                    disabled={false}
                                    onChange={(file) => onChange('File', file)}
                                    coordenadas={Coordenadas}
                                />
                            </span>
                            <span className="adjunto">
                                <ElementInputFileAdjunto
                                    icon={'fa-solid fa-paperclip'}
                                    multiple
                                    disabled={false}
                                    onChange={(document) => onChange('Document', document)}
                                />
                            </span>
                        </div>
                        {
                            props.modalData.slides.length > 0 &&
                            <button className='btn btn-dark' onClick={openModal}>
                                {languageTranslate.general.VerArchivos} {countByType()[0]}, {countByType()[1]}
                                <i className="fa-solid fa-eye" style={{ fontSize: 20, marginLeft: 10 }} />
                            </button>
                        }
                    </div> */}
                    <button onClick={onAddValorizacion} className='btn btn-secondary w-100 mb-3'>
                        {languageTranslate.btnAgregar}
                    </button>
                </ElementStepperContent>

                {/** STEP 2 */}
                <ElementStepperContent
                    step={2}
                    currentStep={props.keyTab}
                    title={languageTranslate.moduloProgramados.trabajos.moduloPreLiquidar.stepper.stepper2.content.title}
                    isLastStep={true}
                    onSubmit={onSave}
                >
                    {
                        Asignaciones?.map((psl: DtoFormAddValorizacion, i: number) =>
                            <CardDetallePreLiquidacion
                                key={i}
                                item={psl}
                                onRemove={onRemoveValorizacion}
                            />
                        )
                    }
                </ElementStepperContent>

            </div>
        </div >
    )
};