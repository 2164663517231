export class IReporteBaremosPeriodoActual {
    ReportePBPeriodo: ReportePBPeriodo = new ReportePBPeriodo()
    ReportePBObra: ReportePBObra[] = []
    Periodo: string = ''
}

class ReportePBPeriodo {
    Preliquidada: number = 0
    Liquidada: number = 0
    Certificada: number = 0
    Produccion: number = 0
}

class ReportePBObra {
    Cantidad: number = 0
    GOM: string = ''
}