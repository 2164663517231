//Dependency External
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Dispatch } from 'redux'
import * as Yup from 'yup'
import { CalcularResumenLiquidaciones, paisMap } from 'sigo-package'

//Adapters
import { addLoading, hideIconMenu, removeLoading } from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterIndexedDB } from '../../../../shared/Infraestructure/AdapterIndexedDB'
import { AdapterValidator } from '../../../../shared/Infraestructure/AdapterValidator'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import { AdapterConfigure } from './AdapterConfigure'

//Repository
import { RepositoryImplMain } from './RepositoryImplMain'

//UseCase
import { UseCaseSelectTrabajo } from '../Application/UseCaseSelectTrabajo'
import { UseCaseUpdateTrabajo } from '../Application/UseCaseUpdateTrabajo'
import { UseCaseUploadPhoto } from '../Application/UseCaseUploadPhoto'
import { UseCaseLoadData } from '../Application/useCaseLoadData'

//Entities
import { DtoFormAddValorizacion, DtoSelectAsignacionValorizacion, DtoSelectItems, DtoSelectTipoMaterial } from '../Domain/DtoAsignacionValorizacion'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoItems } from '../../../../../app/Domain/DtoItems'
import { DtoTipoMaterial } from '../Domain/DtoTipoMaterial'
import { DtoMaterialesUtiRet } from '../Domain/DtoMaterialesUtiRet'
import { ValidarCantidadMaterial } from './Validator'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'

export const Controller = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const dispatch: Dispatch = useDispatch()
  const params = useParams<{ id: string, codParteDiario?: string }>()
  const navigate: NavigateFunction = useNavigate()

  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)

  const [asignacionesTrabajo, setAsignacionesTrabajo] = useState<DtoSelectAsignacionValorizacion[]>([])
  const [trabajo, setTrabajo] = useState<DtoTrabajos>({} as DtoTrabajos)
  const [dataEstadoInterno, setDataEstadoInterno] = useState<Array<object>>([])
  const [dataStockPersonal, setDataStockPersonal] = useState<DtoResponseSelectStockPersonal[]>([])
  const [dataItems, setDataItems] = useState<DtoItems[]>([])
  const [dataDocumentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX[]>([])
  const dataTipoMaterial: DtoTipoMaterial[] = [{ Codigo: 'Instalado', Descripcion: 'Instalado' }, { Codigo: 'Retirado', Descripcion: 'Retirado' }]
  const [Asignaciones, setAsignaciones] = useState<Array<DtoFormAddValorizacion>>([])
  const [keyTab, setKeyTab] = useState(1)
  const [showImageViewer, setShowImageViewer] = useState<boolean>(false)
  const [fileImageViewer, setFileImageViewer] = useState<File | null>(null)

  useEffect(() => {
    if (trabajo && trabajo.Ultima_asignacion) {
      const asignaciones = trabajo.Ultima_asignacion.Valorizaciones.filter(val => {
        if (val.Estado.ID_Estado !== 1) return false
        const { cantidadMOUtilizado, cantidadUOUtilizado } = calcularMOUOUtilizado({ dataComplete: val, label: '', value: '' }, trabajo)
        if (val.CantidadMO - cantidadMOUtilizado > 0) return true
        if (val.CantidadUO - cantidadUOUtilizado > 0) return true
        return user.Pais.Codigo === '480'
      })
      setAsignacionesTrabajo(asignaciones.map(row => ({ label: `${row.ManoObra.Codigo} - ${row.ManoObra.Nombre} (${row.Linea}) -> ${row.UnidadObra.Nombre}`, value: row.LineaCodigoCub, dataComplete: row })))
    }
  }, [trabajo])

  const calcularMOUOUtilizado = (AsignacionValorizacion: DtoSelectAsignacionValorizacion, trabajo: DtoTrabajos): { cantidadMOUtilizado: number, cantidadUOUtilizado: number } => {
    const { MoUo } = CalcularResumenLiquidaciones(trabajo, [trabajo])
    const lineaCodigoCub = MoUo.find(e => e.LineaCodigoCub === AsignacionValorizacion.dataComplete.LineaCodigoCub)
    return !lineaCodigoCub ? { cantidadMOUtilizado: 0, cantidadUOUtilizado: 0 }
      : { cantidadMOUtilizado: lineaCodigoCub.Cantidad, cantidadUOUtilizado: lineaCodigoCub.CantidadUO }
  }

  const { formAddValorizacion, onChange, onSave } = ControllerForm({
    keyTab, Asignaciones, repository, user, dbLocal, trabajo,
    dataEstadoInterno, dataDocumentacionBaremoPEX, params, calcularMOUOUtilizado
  })

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error', false)
      navigate(AdapterConfigure.ROUTE_TRABAJOS, { replace: true })
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando Trabajo...' }))
    const rptaTrabajo = await new UseCaseSelectTrabajo(repository).exec(user, Number(params.id))
    setTrabajo(rptaTrabajo)
    const { EstadoInterno, StockPersonal, Items, DocumentacionBaremoPEX } = await new UseCaseLoadData(repository).exec(rptaTrabajo, user)
    setDataEstadoInterno(EstadoInterno)
    setDataStockPersonal(StockPersonal)
    setDataItems(Items)
    setDocumentacionBaremoPEX(DocumentacionBaremoPEX)
  }

  const onChangeContentForTab = async (newKeyTab: number) => {
    try {
      if (Asignaciones.length < 1 && keyTab === 1) throw new Error(`No ha agregado ninguna Valorizacion.`)
      if (newKeyTab === keyTab) return
      if (newKeyTab < keyTab) return setKeyTab(newKeyTab)
      return setKeyTab(newKeyTab)
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'error', false)
    }
  }

  const onAddValorizacion = async () => {
    try {
      const ctd = formAddValorizacion.values.Archivos
      const faltan = formAddValorizacion.values.DocumentacionBaremo?.Nodos.some((e) => ctd.filter(ar => ar.CodigoNodo === e.Codigo).length < e.CantidadMin)
      if (faltan) return AdapterGenerico.createMessage('', 'Faltan agregar archivos', 'warning', false)
      try { await formAddValorizacion.submitForm() } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'error', false); return null }
      try { AdapterValidator.validate(await formAddValorizacion.validateForm()) } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null }
      if (formAddValorizacion.values.CantidadMOPreliquidada > formAddValorizacion.values.CantidadMODisponible && user.Pais.Codigo !== '480') //Solo para colombia luego corregir
        throw Error(`Ingrese Cant MO Preliquidar igual o menor a Cant Disponible MO`)
      if (formAddValorizacion.values.CantidadUOPreliquidada > formAddValorizacion.values.CantidadUODisponible) throw Error(`Ingrese Cant UO Preliquidar igual o menor a Cant Disponible UO`)
      if (formAddValorizacion.values.CantidadMOPreliquidada === 0 && formAddValorizacion.values.CantidadUOPreliquidada === 0) throw Error(`Ingrese Cant UO o Cant MO Preliquidar mayor a 0`)
      if (formAddValorizacion.values.CantidadUOPreliquidada === '') formAddValorizacion.values.CantidadUOPreliquidada = 0
      if (user.Pais.Codigo === '512') ValidarDisponibleStock(formAddValorizacion.values)
      if (user.Pais.Codigo === '480') ValidarCantidadMaterial(formAddValorizacion.values)

      setAsignaciones([...Asignaciones, formAddValorizacion.values])
      setAsignacionesTrabajo(asignacionesTrabajo?.filter(el => el.dataComplete.LineaCodigoCub !== formAddValorizacion.values.Valorizacion.value))
      AdapterGenerico.createMessage('¡Éxito!', `Agregado correctamente.`, 'success', false)
      await reloadForm()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  const ValidarDisponibleStock = (FormAddValorizacion: DtoFormAddValorizacion) => {
    const { CantidadUOPreliquidada, Valorizacion: { dataComplete: { LineaCodigoCub } } } = FormAddValorizacion
    if (+CantidadUOPreliquidada < 1) return
    const val = trabajo.ColeccionManoObra[0].Valorizacion.find((va: any) => va.DetalleChile.LineaCodigoCub === LineaCodigoCub)
    if (!val) throw Error(`No se encontró Valorización con LineaCodigoCub: ${LineaCodigoCub} en Mano Obra con ID_ManoObra: ${trabajo.ColeccionManoObra[0].ID_ManoObra}.`)
    const { DatosCatalogoMO: { DatosUnidadObraMaterial: { Materiales } } } = val
    FormAddValorizacion.Materiales = Materiales
    if (!Materiales.length) return
    for (const material of Materiales) {
      const disponible = dataStockPersonal.reduce((disponible, stock) => {
        if (stock.Reserva !== material.Codigo) return disponible
        return disponible + stock.Despacho - stock.Devolucion - stock.Liquidacion + stock.DespachoTR - stock.DevolucionTR
      }, 0)
      const utilizado = utilizadoStockEnMemoria(material.Codigo)
      if ((material.CantidadMax * +CantidadUOPreliquidada) > (disponible - utilizado))
        throw Error(`👉 No tiene stock disponible (${(disponible - utilizado)}) del material on Código <label style='font-weight: bold'>${material.Codigo}</label> para Preliquidar.`)
    }
  }

  const utilizadoStockEnMemoria = (codigo: string) => {
    return Asignaciones.reduce((disponible, asignacion) => {
      return disponible + asignacion.Materiales.reduce((acc: number, el) => {
        if (codigo !== el.Codigo) return acc
        return acc + (el.CantidadMax * Number(asignacion.CantidadUOPreliquidada))
      }, 0)
    }, 0)
  }

  const onRemoveValorizacion = async (value: string) => {
    try {
      const asignacion = Asignaciones.find(el => el.Valorizacion.value === value)
      if (!asignacion) {
        throw Error(`No se pudo obtener asigancion`)
      }
      setAsignacionesTrabajo(asignacionesTrabajo?.concat([asignacion.Valorizacion.dataComplete]
        .map(row => ({ label: `${row.ManoObra.Codigo} - ${row.ManoObra.Nombre} (${row.Linea}) -> ${row.UnidadObra.Nombre}`, value: row.LineaCodigoCub, dataComplete: row }))))
      setAsignaciones(Asignaciones.filter(el => el.Valorizacion.value !== value))
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  const reloadForm = async () => {
    formAddValorizacion.resetForm()
    formAddValorizacion.setErrors({})
    await formAddValorizacion.setTouched({})
  }

  return {
    init,
    keyTab,
    onChangeContentForTab,
    onSave,
    onAddValorizacion,
    onRemoveValorizacion,
    Asignaciones,
    formAddValorizacion,

    onChange,
    asignacionesTrabajo,
    trabajo,
    dataItems,
    dataTipoMaterial,
    dataStockPersonal,
    fileImageViewer,
    showImageViewer,
    setShowImageViewer,
    setFileImageViewer
  }
}

const ControllerForm = (props: {
  keyTab: number, Asignaciones: DtoFormAddValorizacion[], repository: RepositoryImplMain, user: EntityDataUsuario,
  dbLocal: AdapterIndexedDB, trabajo: DtoTrabajos, dataEstadoInterno: any[],
  dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[], params: Readonly<Partial<{ id: string; codParteDiario?: string | undefined; }>>,
  calcularMOUOUtilizado: (AsignacionValorizacion: DtoSelectAsignacionValorizacion, trabajo: DtoTrabajos) => { cantidadMOUtilizado: number, cantidadUOUtilizado: number }
}) => {
  const { Asignaciones, trabajo, repository, user, dataEstadoInterno, dataDocumentacionBaremoPEX, calcularMOUOUtilizado } = props
  const dispatch: Dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { codParteDiario } = props.params

  const formAddValorizacion = useFormik({
    initialValues: {
      CantidadMOPreliquidada: '',
      CantidadUOPreliquidada: '',
      CantidadMOAsignada: '',
      CantidadMODisponible: '',
      CantidadUOAsignada: '',
      CantidadUODisponible: '',
      ID_Valorizacion: '',
      Valorizacion: new DtoSelectAsignacionValorizacion(),
      TipoMaterial: new DtoSelectTipoMaterial(),
      Items: [],
      DocumentacionBaremo: new DtoDocumentacionBaremoPEX(),
      Archivos: [],
      Materiales: []
    } as DtoFormAddValorizacion,
    validationSchema: Yup.object({
      // tab1
      Valorizacion: Yup.object().when([], { is: () => (props.keyTab === 1), then: Yup.object().required('Seleccione una Valorizacion').nullable() }),
      CantidadMOPreliquidada: Yup.number().when([], {
        is: () => props.keyTab === 1,
        then: Yup.number().min(0, 'Ingrese una cantidad MO a Preliquidar mayor o igual a 0').required('Ingrese una cantidad MO a Preliquidar').nullable()
      }),
      CantidadUOPreliquidada: Yup.number().when([], {
        is: () => props.keyTab === 1,
        then: Yup.number().min(0, 'Ingrese una cantidad MO a Preliquidar mayor o igual a 0').required('Ingrese una cantidad MO a Preliquidar').nullable()
      }),
    }),
    onSubmit: async (values, formikHelpers) => { },
  })

  const onChange = async (name: string, value: any) => {
    try {
      if (value === null) { return }
      switch (name) {
        case 'Valorizacion':
          formAddValorizacion.setFieldValue(name, value)
          //#region Calcular disponible
          if (!Object.keys(trabajo).length) throw Error(`No se puede calcular Valorizacion`)
          const { cantidadMOUtilizado, cantidadUOUtilizado } = calcularMOUOUtilizado(value, trabajo)
          //#endregion Calcular disponible
          formAddValorizacion.setFieldValue('CantidadMOAsignada', value.dataComplete.CantidadMO)
          formAddValorizacion.setFieldValue('CantidadUOAsignada', value.dataComplete.CantidadUO)
          formAddValorizacion.setFieldValue('CantidadMODisponible', value.dataComplete.CantidadMO - cantidadMOUtilizado)
          formAddValorizacion.setFieldValue('CantidadUODisponible', value.dataComplete.CantidadUO - cantidadUOUtilizado)
          formAddValorizacion.setFieldValue('TipoMaterial', null)
          formAddValorizacion.setFieldValue('Items', [])
          formAddValorizacion.setFieldValue('Images', [])
          formAddValorizacion.setFieldValue('Documents', [])
          formAddValorizacion.setFieldValue('Archivos', [])
          formAddValorizacion.setFieldValue('Materiales', [])
          formAddValorizacion.setFieldValue('CantidadMOPreliquidada', '')
          formAddValorizacion.setFieldValue('CantidadUOPreliquidada', '')
          //Buscar Documentacion Baremo
          const documentacionBaremo = dataDocumentacionBaremoPEX.find(e => e.CodigoMO === value.dataComplete.ManoObra.Codigo)
          if (!!!documentacionBaremo) console.warn(`No existe DocumentacionBaremoPEX para el codigo ManoObra ${value.dataComplete.ManoObra.Codigo}`)
          const defaultBaremo = new DtoDocumentacionBaremoPEX()
          const newNodo = new DtoNodoDoc()
          newNodo.id = 999999
          newNodo.label = 'OTROS ANEXOS'
          newNodo.Titulo = 'OTROS ANEXOS'
          newNodo.Codigo = 'Cod999999'
          newNodo.Descripcion = 'OTROS ANEXOS'
          newNodo.Extension = ['dwg', 'rar', 'pdf', 'doc', 'docx', 'txt', 'png', 'jpeg', 'jpg', 'bmp', 'webp', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mgs', 'gzip', 'gz', '7z', 'html', 'csv', 'avi', 'mpg', 'mkv', 'mov', 'mp4', 'wmv', 'mp3', 'wav', 'apk', "ALL"]
          newNodo.Size.Size = 50
          newNodo.Size.UM = 'MB'
          newNodo.CantidadMin = paisMap[trabajo.Pais.Codigo].CantidadMin

          defaultBaremo.Nodos = [newNodo]

          formAddValorizacion.setFieldValue('DocumentacionBaremo', documentacionBaremo ? documentacionBaremo : defaultBaremo)
          break
        case 'CantidadMOPreliquidada':
        case 'CantidadUOPreliquidada':
          formAddValorizacion.setFieldValue(name, value)
          trabajo.Pais.Codigo === '480' && formAddValorizacion.setFieldValue('CantidadUOPreliquidada', 0)
          break
        case 'Instalado':
          const exist = formAddValorizacion.values.Items.find((e: any) => e.dataComplete.Codigo === value.dataComplete.Item.Codigo)
          if (exist) throw new Error(`Ya se agregó Material con el mismo Código`)
          const utilizadoLocal = CalcularDisponibleStockLocal(value.dataComplete.Item.Codigo)
          value.disponible = value.dataComplete.Despacho + value.dataComplete.DespachoTR - value.dataComplete.Devolucion - value.dataComplete.DevolucionTR - value.dataComplete.Liquidacion - utilizadoLocal
          value.tipo = 'Instalado'
          const materialUtiRetI: DtoMaterialesUtiRet = new DtoMaterialesUtiRet()
          materialUtiRetI.Codigo = value.dataComplete.Item.Codigo
          materialUtiRetI.Descripcion = value.dataComplete.Item.Descripcion
          materialUtiRetI.UnidadMedida.ID_UnidadMedida = value.dataComplete.Item.UnidadMedida.ID_UnidadMedida
          materialUtiRetI.UnidadMedida.Nombre = value.dataComplete.Item.UnidadMedida.Nombre
          value.dataComplete = materialUtiRetI
          formAddValorizacion.setFieldValue('Items', [...formAddValorizacion.values.Items, value])
          break
        case 'Retirado':
          const existMat = formAddValorizacion.values.Items.find((e: any) => e.dataComplete.Codigo === value.dataComplete.Codigo)
          if (existMat) throw new Error(`Ya se agregó Material con el mismo Código`)
          value.tipo = 'Retirado'
          const materialUtiRetR: DtoMaterialesUtiRet = new DtoMaterialesUtiRet()
          materialUtiRetR.Codigo = value.dataComplete.Codigo
          materialUtiRetR.Descripcion = value.dataComplete.Descripcion
          materialUtiRetR.UnidadMedida.ID_UnidadMedida = value.dataComplete.UnidadMedida[0].ID_UnidadMedida
          materialUtiRetR.UnidadMedida.Nombre = value.dataComplete.UnidadMedida[0].Nombre
          value.dataComplete = materialUtiRetR
          formAddValorizacion.setFieldValue('Items', [...formAddValorizacion.values.Items, value])
          break
        case 'deleteItem':
          formAddValorizacion.setFieldValue('Items', formAddValorizacion.values.Items.filter((e: any) => e.value !== value))
          break
        case 'TipoMaterial':
          formAddValorizacion.setFieldValue(name, value)
          break
        case 'QuantityChange':
          const newItems = [...formAddValorizacion.values.Items]
          for (let item of newItems) {
            if (item.value === value.Item) {
              if (value.Cantidad > value.Disponible) throw new Error(`La Cantidad Ingresada supera lo disponible`)
              item.dataComplete.Cantidad = value.Cantidad
              item.dataComplete.CantidadInformada = value.Cantidad
              break
            }
          }
          formAddValorizacion.setFieldValue('Items', newItems)
          break
        default:
          formAddValorizacion.setFieldValue(name, value)
          break
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    }
  }

  const CalcularDisponibleStockLocal = (codigo: string): number => {
    return Asignaciones.reduce((ac: number, asi) => ac + asi.Items.reduce((acc: number, e: DtoSelectItems) => {
      if (e.dataComplete.Codigo === codigo) return acc + e.dataComplete.Cantidad
      return acc
    }, 0), 0)
  }

  const onSave = async () => {
    try {
      if (Asignaciones.length < 1) throw new Error(`No ha agregado ninguna Valorización.`)
      if (!Object.keys(trabajo).length) throw Error(`No Hay trabajo para actualizar.`)
      const Files = agregarID_Valorizacion()
      const data: any = { user, trabajo, Asignaciones, dataEstadoInterno, params: props.params }
      dispatch(addLoading({ textLoading: '🛠 Actualizando Trabajo...' }))
      await new UseCaseUploadPhoto(repository).exec(Files, user, trabajo.ID_Trabajo)
      const trabajoUpdate = await new UseCaseUpdateTrabajo(repository).exec(data)
      dispatch(removeLoading())
      if (trabajoUpdate.length !== 1) throw Error(`Error al PreLiquidar.`)
      await AdapterGenerico.createMessage('¡Éxito!',
        codParteDiario
          ? `PreLiquidado correctamente y reportado al parte diario con Fecha: ${codParteDiario.slice(6, 8)}/${codParteDiario.slice(4, 6)}/${codParteDiario.slice(0, 4)}`
          : `PreLiquidado correctamente.`,
        'success', false)
    } catch (error) {
      dispatch(removeLoading())
      console.error(error)
      await AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      navigate(codParteDiario ? `${AdapterConfigure.ROUTE_PARTEDIARIO_TRABAJO}/${trabajo.ID_Trabajo}/${codParteDiario}` : AdapterConfigure.ROUTE_TRABAJOS, { replace: false })
    }
  }

  const agregarID_Valorizacion = (): File[] => {
    const Files: File[] = []
    for (const [i, item] of Asignaciones.entries()) {
      let IDValorizacion = generarID_Valorizacion() + i + 1
      const ruta: string = `OBRA_${trabajo.ColeccionObras[0].ID_incidencia}_${trabajo.ID_GOM}_${trabajo.ID_Trabajo}_${IDValorizacion}_Fotos_Anexos_`
      item.ID_Valorizacion = IDValorizacion
      for (const img of item.Archivos) {
        img.Ruta = ruta
        if (img.File) {
          img.Filename = `${ruta}${img.File.name}`
          const newFile = new File([img.File], `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${ruta}-${img.Filename}`, {
            type: img.File.type,
            lastModified: img.File.lastModified
          })
          Files.push(newFile)
        }
      }
    }
    return Files
  }

  const generarID_Valorizacion = (): number => {
    return trabajo.Ultima_PreLiquidacion.Valorizacion.reduce((max, val) => max < val.ID_Valorizacion ? val.ID_Valorizacion : max, 0)
  }

  return { formAddValorizacion, onChange, onSave }
}
