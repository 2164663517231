import { useSelector } from 'react-redux'
import { Container } from '../../../shared/Components/Container/Container'
import { Badge, Card, ListGroup } from 'react-bootstrap'
import { RootState } from '../../../shared/Infraestructure/AdapterStore'
import { Children, useEffect, useRef, useState } from 'react'
import Chart from 'chart.js/auto'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { ChartOptions, Plugin } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

interface DoughnutChartOptions extends ChartOptions<'doughnut'> {
  centerLabel?: string
}

const centerLabelPlugin: Plugin<'doughnut', Record<string, unknown>> = {
  id: 'centerLabelPlugin',
  afterDraw: (chart) => {
    const { ctx, width, height, options } = chart;

    const centerLabel = (options as DoughnutChartOptions).centerLabel

    if (centerLabel) {
      ctx.save();
      ctx.font = '12px Arial'
      ctx.fillStyle = '#333'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(centerLabel, width / 2, height / 2)
      ctx.restore()
    }
    ctx.save()
    ctx.font = '20px Arial'
    ctx.fillStyle = '#333'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText('820 PB', width / 2, height / 2 + 20)
    ctx.restore()
  },
}

export const ViewMainColombia9512 = () => {

  const { reporteBaremosPeriodoActual } = useSelector((state: RootState) => state.home)
  const [total, setTotal] = useState(0)
  const chartRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const total = Object.values(reporteBaremosPeriodoActual.ReportePBPeriodo).reduce((sum, value) => {
      return typeof value === 'number' ? sum + value : sum
    }, 0)
    setTotal(total)
  }, [reporteBaremosPeriodoActual])

  useEffect(() => {
    const data = {
      labels: ['Preliquidada', 'Liquidada', 'Certificada', 'Producción',],
      datasets: [{
        data: [
          reporteBaremosPeriodoActual.ReportePBPeriodo.Preliquidada.toFixed(2),
          reporteBaremosPeriodoActual.ReportePBPeriodo.Liquidada.toFixed(2),
          reporteBaremosPeriodoActual.ReportePBPeriodo.Certificada.toFixed(2),
          reporteBaremosPeriodoActual.ReportePBPeriodo.Produccion.toFixed(2),
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)'
        ],
        borderWidth: 1
      }]
    }
    const ctx = chartRef.current?.getContext('2d')
    if (ctx) {
      const chartInstance = new Chart(ctx, {
        data,
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return `${value} PB`
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
      return () => {
        if (chartRef) {
          chartInstance.destroy()
        }
      }
    }
  }, [reporteBaremosPeriodoActual])

  const chartData = {
    labels: ['Ejecutado PB', 'Restante PB'],
    datasets: [
      {
        data: [total.toFixed(2), (820 - total).toFixed(2)],
        backgroundColor: ['rgba(76, 175, 80, 0.7)', 'rgba(255, 99, 132, 0.7)']
      }
    ]
  }

  const options: DoughnutChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14
          }
        }
      },
      datalabels: {
        labels: {
          font: {
            font: { size: 16 }
          }
        }
      }
    },
    centerLabel: 'Objetivo',
  }

  return (
    <Container style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem'
    }}>
      <Card className='text-center' style={{ backgroundColor: 'whitesmoke' }}>
        <Card.Header
          style={{ fontWeight: 'bold', color: 'gray' }}
        >
          PRODUCCIÓN DEL PERIODO {reporteBaremosPeriodoActual.Periodo}
        </Card.Header>
        <Card.Body>
          <div>
            <Doughnut data={chartData} options={options} plugins={[centerLabelPlugin]} />
          </div>
        </Card.Body>
      </Card>
      <div>
        <canvas ref={chartRef} />
      </div>
      <ListGroup as='ol' numbered>
        {
          Children.toArray(
            reporteBaremosPeriodoActual.ReportePBObra.map(el => (
              <ListGroup.Item
                as='li'
                className='d-flex justify-content-between align-items-start'
                style={{ width: '100%' }}
                variant='info'
              >
                <div className='ms-2 me-auto'>
                  <div className='fw-bold'>{el.GOM.slice(5)}</div>
                </div>
                <Badge bg='info' style={{ fontSize: 14, color: 'black' }} pill>
                  { el.Cantidad.toFixed(2)}
                </Badge>
              </ListGroup.Item>
            ))
          )
        }

      </ListGroup>
    </Container>
  )
}
