import { DtoDocumentacionBaremoPEX } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { DtoMaterialAmap } from '../../../../../app/Domain/DtoUnidadObraMaterial'
import { DtoResponseSelectStockPersonal } from '../../../../Master/Home/Domain/DtoResponseSelectStockPersonal'
import { DtoCodigoNombre } from '../../../../shared/Domain/Dto/DtoCodigoNombre'
import { DtoCodigoNombreUnidad } from '../../../../shared/Domain/Dto/DtoCodigoNombreUnidad'
import { DtoEstado } from '../../../../shared/Domain/Dto/DtoEstado'
import { DtoFilesDoc } from '../../Trabajos/Domain/DtoFilesDoc'
import { DtoMaterialesUtiRet } from './DtoMaterialesUtiRet'
import { DtoTipoMaterial } from './DtoTipoMaterial'
export class DtoAsignacionValorizacion {
  ID_AsignacionValorizacion: number = 0
  ID_Amap: number = 0
  Linea: string = ''
  Codigo: string = ''
  CantidadMO: number = 0
  CantidadUO: number = 0
  Especialidad: DtoCodigoNombre = new DtoCodigoNombre()
  Actividad: DtoCodigoNombre = new DtoCodigoNombre()
  Clave: DtoCodigoNombre = new DtoCodigoNombre()
  ManoObra: DtoCodigoNombreUnidad = new DtoCodigoNombreUnidad()
  UnidadObra: DtoCodigoNombreUnidad = new DtoCodigoNombreUnidad()
  LineaCodigoCub: string = ''
  CodigoCub: string = ''
  Estado: DtoEstado = new DtoEstado()
}

export class DtoSelectAsignacionValorizacion {
  label: string = ''
  value: string = ''
  dataComplete: DtoAsignacionValorizacion = new DtoAsignacionValorizacion()
}
export class DtoSelectTipoMaterial {
  label: string = ''
  value: string = ''
  dataComplete: DtoTipoMaterial = new DtoTipoMaterial()
}
export interface DtoSelectItems {
  tipo: string
  disponible: number
  label: string
  value: string
  dataComplete: DtoMaterialesUtiRet
}
export interface DtoSelectStockPersonal {
  label: string
  value: string
  dataComplete: DtoResponseSelectStockPersonal
}
export interface DtoFormAddValorizacion {
  CantidadMOPreliquidada: number | string
  CantidadUOPreliquidada: number | string
  CantidadMOAsignada: number | string
  CantidadMODisponible: number | string
  CantidadUOAsignada: number | string
  CantidadUODisponible: number | string
  ID_Valorizacion: number | string
  // Images: Array<EntityModalImage>
  // Documents: Array<EntityModalDocument>
  Valorizacion: DtoSelectAsignacionValorizacion
  TipoMaterial: DtoSelectTipoMaterial
  Items: DtoSelectItems[]
  DocumentacionBaremo: DtoDocumentacionBaremoPEX
  Archivos: DtoFilesDoc[]
  Materiales: DtoMaterialAmap[]
}